.eLearningMobileFeedbackRoot {
    padding-top: 80px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #f8f6ff;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    margin-bottom: 70px;

    .eLearningMobileFeedbackLoaderContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
    }

    .eLearningMobileFeedbackContentContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        gap: 14px;
    }

    .eLearningMobileFeedbackText {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 16px;
        color: black;
    }

    .eLearningMobileFeedbackPaginationContainer {
        display: flex;
        width: 100%;
        justify-content: center;

        .eLearningMobileFeedbackPaginationStack {
            margin-top: 1rem;
        }
    }
}
