.eventMobileCardRoot {
    width: 150px;
    height: 140px;
    border-radius: 20px;
    box-shadow: 5px 5px 21px 4px #00000014;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .imageContainer {
        width: 100%;
        height: calc(100% - 30px);
        display: flex;
        justify-content: center;
        align-items: center;

        .image {
            width: 130px;
            height: 83px;
            border-radius: 5px;
        }
    }

    .titleContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        border-radius: 0 0 20px 20px;

        h3 {
            font-weight: 700;
            font-size: 14px;
            color: white;
        }
    }
}
