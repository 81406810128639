.HrUserCard {
    color: #212121;
    text-decoration: none;
    // max-width: 400px;
    padding: 10px;
    width: 100%;
    min-height: 57px;
    max-height: auto;
    border-radius: 16px;
       // background: #eef2fb;
       background: rgba(0, 126, 99, 0.25);

    article {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mvUserCardContentWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            // min-width: 316px;

            .infoContainer {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;

                .nameIconContainer {
                    position: relative;
                    width: 45px;
                    height: 42px;
                    .nameCharContainer {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        min-width: 40px;
                        padding: 8px;
                        border-radius: 50%;
                        color: white;
                        font-size: 18px;
                        text-transform: uppercase;
                    }
                    .dotWrapper {
                        position: absolute;
                        right: 0;
                        bottom: 4px;

                        .dotIcon {
                            font-size: 16px;
                            color: #00ba34;
                        }
                    }
                }

                .userInfoContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
                    align-items: flex-start;

                    .name {
                        font-size: 16px;
                        font-weight: 500;
                        color: #000000b2;
                    }

                    .email {
                        font-size: 12px;
                        font-weight: 400;
                        color: #787878d9;
                    }

                    .message {
                        font-size: 10px;
                        font-weight: 400;
                    }
                }
            }

            .rightChildContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                margin-right: -19px;

                .detailWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .designation {
                        font-size: 10px;
                        font-weight: 500;
                        color: #787878d9;
                    }

                    .location {
                        font-size: 10px;
                        font-weight: 500;
                        color: #787878d9;
                    }
                }

                .rightArrowIconWrapper {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: -10px;

                    .rightArrowIcon {
                        font-size: 10px;
                        color: white;
                    }
                }
            }
        }
    }
}

.cardSearchUser {
    color: #212121;
    text-decoration: none;
    // max-width: 400px;
    padding: 10px;
    width: 100%;
    min-height: 57px;
    max-height: auto;
    border-radius: 16px;
        background: #eef2fb;
       //background: rgba(0, 126, 99, 0.25);
   
    article {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mvUserCardContentWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            // min-width: 316px;

            .infoContainer {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;

                .nameIconContainer {
                    position: relative;
                    width: 45px;
                    height: 42px;
                    .nameCharContainer {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        min-width: 40px;
                        padding: 8px;
                        border-radius: 50%;
                        color: white;
                        font-size: 18px;
                        text-transform: uppercase;
                    }
                    .dotWrapper {
                        position: absolute;
                        right: 0;
                        bottom: 4px;

                        .dotIcon {
                            font-size: 16px;
                            color: #00ba34;
                        }
                    }
                }

                .userInfoContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
                    align-items: flex-start;

                    .name {
                        font-size: 16px;
                        font-weight: 500;
                        color: #000000b2;
                    }

                    .email {
                        font-size: 12px;
                        font-weight: 400;
                        color: #787878d9;
                    }

                    .message {
                        font-size: 10px;
                        font-weight: 400;
                    }
                }
            }

            .rightChildContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                margin-right: -19px;

                .detailWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .designation {
                        font-size: 10px;
                        font-weight: 500;
                        color: #787878d9;
                    }

                    .location {
                        font-size: 10px;
                        font-weight: 500;
                        color: #787878d9;
                    }
                }

                .rightArrowIconWrapper {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: -10px;

                    .rightArrowIcon {
                        font-size: 10px;
                        color: white;
                    }
                }
            }
        }
    }
}
