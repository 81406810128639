.SubmitVideoMainContainer {
    padding-top: 10%;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background: #f8f6ff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    margin-bottom: 70px;
    position: relative;

    .contentContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 90px;

        .buttonContainer {
            display: flex;
            justify-content: flex-start;
            margin-top: 35px;
            width: 100%;
            padding: 20px;

            .videoQuizTitle {
                font-size: 18px;
                font-weight: 700;
                color: #1b3f8f;
            }
        }

        .videoContentWrapper {
            margin-left: 0px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            gap: 10px;
            margin: 10px;

            .text {
                font-size: 16px;
                font-weight: 700;
            }

            .paper {
                display: flex;
                flex-direction: column;
                width: 100%;
                min-width: 340px;
                padding: 10px 0px;
                min-height: 330px;
                max-height: auto;
                justify-content: center;
                align-items: center;
                background: white;
                border-radius: 10px;

                .buttonGroup {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;

                    .button {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        height: 100px;
                        background: rgba(27, 63, 143, 0.2);
                        border-radius: 7px;

                        .icon {
                            font-size: 20px;
                            font-weight: 400;
                            color: #1b3f8f;
                        }

                        .buttonText {
                            font-size: 16px;
                            font-weight: 400;
                            color: #1b3f8f;
                        }
                    }

                    .fileInput {
                        display: none;
                    }
                }

                .videoContainer {
                    padding: 10px 0px;
                    width: 300px;
                    min-width: 250px;
                    max-height: 300px;

                    .video {
                        width: 300px;
                        min-width: 250px;
                        max-height: 300px;
                    }
                }
            }
        }
    }

    .submitBtnContainer {
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        background: white;
        height: 80px;
        padding: 0px 10px;
        align-items: center;

        .submitButton {
            cursor: pointer;
            text-transform: capitalize;
            font-size: 16px;
            height: 43px;
            background-color: #135098;
            width: 100%;
            color: white;
            border-radius: 7px;
        }
    }
}
