.user-tabs {
    background-color: #F8F7FA;

    a {
        text-decoration: none;
        color: #2F2F2F;
    }

    .tab {
        height: 100%;
        padding: 12px 10px;
        width: 250px;
        cursor: pointer;
        border-right: 1px solid rgb(0,0,0,0.25);
        background-color: rgba(217, 217, 217, 0.5);

        .user-name {
            font-size: 15px;
            font-weight: bold;
            color: #2F2F2F;
        }

        .type-div {
            background-color: white;
            padding: 3px;
            height: 18px;
            width: 25px;
            border-radius: 3px;
            position: relative;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .status {
            font-size: 11px;
            color: #2F2F2F;
        }
    }

    .active {
        background-color: #7455F6;
        color: white;

        .user-name, .status {
            color: white;
        }
    }
}