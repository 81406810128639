.slideTabContainer {
    position: relative;
    min-width: 300px;
    max-width: 345px;
    height: 120px;
    cursor: pointer;
    border: 1px solid #1950de33;
    text-align: center;
    box-shadow: 0px 0px 4px 0px #00000040;
    box-sizing: border-box;
    visibility: visible;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    z-index: 10;

    .slideContent {
        padding: 20px;
        overflow: hidden;
        font-size: 7px !important;
        line-height: 8px;
        text-align: left;
        display: flex;
        gap: 1px;
    }

    .slideIcon {
        position: absolute;
        bottom: 4px;
        right: 4px;
        font-size: 25px;
        color: white;
    }

    .slideNumber {
        position: absolute;
        bottom: -3px;
        left: -3px;
        background: #1b3f8f;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 41px;
        color: #ffffff !important;
        padding: 5px 15px;
        border-radius: 0px 20px 0px 10px;
        font-weight: 700;
        font-size: 12px;
        z-index: 20;
    }
}
