.dateRangeSelectorField {
  border: 1px solid #00000033;
  border-radius: 5px;
  background-color: transparent;
  height: 40px;
  min-width: 200px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #00000080;
  font-size: 14px;
  padding: 5px;
  justify-content: space-between;
}

.elearning1CalenderStyles {
  .rmdp-range {
    background-color: #4781ff !important;
  }

  .rmdp-week-day {
    color: #1b3f8f !important;
  }

  .rmdp-day.rmdp-today span {
    background-image: linear-gradient(45deg, #1b3f8f, #635bff) !important;
  }
}
