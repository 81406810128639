.GradingHeaderRoot {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    // background: var(--mobileTheme);
    padding: 10px 20px;
    z-index: 100;
    align-items: flex-start;

    .GradingHeaderLeftSection {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;

        .GradingHeaderArrowIcon {
            color: white;
            cursor: pointer;
        }

        .GradingHeaderProfileContainer {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 6px;

            .GradingHeaderProfileImage {
                width: 30px;
                height: 30px;
                border-radius: 41px;
            }

            .GradingHeaderUserDetails {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .GradingHeaderUserName {
                    font-size: 18px;
                    line-height: 17px;
                    font-weight: 700;
                    color: white;
                }

                .GradingHeaderRole {
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 400;
                    color: white;
                }

                .GradingHeaderLessonDetail {
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 400;
                    color: white;
                }
            }
        }
    }

    .GradingHeaderRightSection {
        display: flex;
        align-items: flex-end;
        height: 60px;

        .GradingHeaderViewSlides {
            text-decoration: underline;
            cursor: pointer;
            font-size: 12px;
            line-height: 17px;
            font-weight: 400;
            color: white;
        }
    }
}
