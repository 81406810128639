.myLibraryMobileDetailRoot {
  width: 100%;
  min-height: 100vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 140px;

  .myLibraryMobileDetailArticle {
    display: flex;
    margin: 20px;
    margin-bottom: 0px;
    justify-content: space-between;
    align-items: flex-start;
    height: 60px;

    .myLibraryMobileDetailBackButton {
      cursor: pointer;
      margin-top: 7px;
    }

    .myLibraryMobileDetailHeader {
      font-size: 13px;
      font-weight: 700;
      color: #1d1719;
      text-align: center;
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-all;
      margin-left: auto;
      margin-right: auto;
    }

    .myLibraryMobileDetailFeedbackWrapper {
      display: flex;
      height: 60px;
      align-items: flex-end;

      .myLibraryMobileDetailFeedbackButton {
        background: rgba(19, 80, 152, 0.1);
        border-radius: 5px;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
      }
    }
  }

  .myLibraryMobileDetailslidesBox {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 90;
  }
}
