.add-note-root-container {
  padding-top: 12%;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #f8f6ff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  margin-bottom: 70px;
  position: relative;

  .text-area-container {
    padding: 60px 20px;

    .addNotesContainer {
      width: 100%;
      max-width: 450px;
      background: white;
      height: 129px !important;
      outline: none;
      border: none;
      border-radius: 5px;
      padding: 20px;
      font-size: 13px;
      font-weight: 400;
      color: #848484;
    }
  }

  .btnContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: white;
    gap: 14px;
    height: 80px;
    padding: 0px 10px;
    align-items: center;

    .addButton {
      cursor: pointer;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 700;
      height: 47px;
      background-color: #135098;
      width: 149px;
      color: white;
      border-radius: 7px;
    }

    .cancelButton {
      cursor: pointer;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 700;
      height: 47px;
      background: #1b3f8f59;
      width: 149px;
      color: #1b3f8f;
      border-radius: 7px;
    }
  }
}
