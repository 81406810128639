/* width */
.scroll::-webkit-scrollbar {
    width: 2px;
    height: 3px;
  }
  
  /* Track */
  .scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .scroll::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .scroll::-webkit-scrollbar-thumb:hover {
    background: #8f8f8f; 
  }


/* slide custom scroll bar */


  /* width */
.slidescroll::-webkit-scrollbar {
  /* width: 2px; */
  height: 10px;
}

/* Track */
.slidescroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey;
  background: #D7B1B14D; 
  border-radius: 20px;
}
 
/* Handle */
.slidescroll::-webkit-scrollbar-thumb {
  background: #858585; 
  border-radius: 20px;
}

/* Handle on hover */
.slidescroll::-webkit-scrollbar-thumb:hover {
  background: #8f8f8f; 
}

