.paymentLogs-mobile-root-container {
  margin-top: 41px;
  margin-left: 20px;
  margin-right: 20px;

  .paymentLogs-accordian-root {
    margin-bottom: 7px;
    box-shadow: none;
    position: static;
    border-radius: 6px !important;
    border: 1px solid #0000004d;
    box-shadow: 0px 0px 0px -2px #00000014;

    .accordian-summary-container {
      position: none;
      .expand-icon-container {
        width: 25px;
        height: 25px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .accordian-summary-text {
        font-weight: 500;
        font-size: 12px;
      }
    }

    .accordian-details-container {
      border-radius: 6px;
      padding: 0px;

      .table-container-root {
        border: 1px solid #e7e7e7;

        .table-head-root-container {
          background-color: #1b3f8f40;

          .table-head-row {
            border-bottom: none;

            .table-cell {
              color: #1b3f8f;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }

        .table-body-cell {
          color: #848484;
          font-weight: 400;
          font-size: 12px;
        }
        .table-body-cell-first-child {
          padding-left: 20px;
        }
      }

      .notes-container {
        width: 100%;
        padding: 17px 30px;

        .notes-input-field {
          border: 1px solid #00000040;
          width: 100%;
          border-radius: 12px;
          min-height: 60px;
          max-height: 120px;
          overflow-y: auto;
        }
      }
    }
  }

  .loaderContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0px;
  }
}
