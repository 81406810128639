.page-title {
    font-size: 15px;
    color: #263746;
    font-weight: 600;
    margin: 0;
    display: inline-block;
    padding-right: 15px;
    padding-bottom: 15px;
}

.rc-dropdown .rc-menu {
    position: absolute;
    top: -20px;
    z-index: 1000;
    width: 100px;
    right: -10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.rc-dropdown .rc-menu li {
    padding: 5px 10px;
}

.breadcrumb {
    padding: 0;
    font-size: 12px;
    margin-bottom: 6px;
}

.breadcrumb li {
    display: inline-block;
}

.breadcrumb li a {
    color: #353c48 !important;
    cursor: pointer;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb>li+li:before {
    content: ">"!important;
    color: #777;
    padding-left: 5px;
}

.form-control:focus {
    box-shadow: none;
}

button {
    border-width: 0px;
    font-size: 14px;
}

.success-btn {
    background-color: #5cc6a3;
    color: #fff;
    cursor: pointer;
    text-align: center;
    padding: 0 16px;
    border-radius: 4px;
    line-height: 36px;
    font-weight: 500;
    outline: none;
}

.cancel-btn {
    background-color: white;
    color: #263746;
    border: 1px solid #263746;
    cursor: pointer;
    text-align: center;
    padding: 0 15px;
    border-radius: 4px;
    line-height: 34px;
    font-weight: 500;
    outline: none;
}

.display-flex {
    display: flex;
}

.justify-content-right {
    justify-content: right;
}

.bg-ov-routine {
    background-color: #5cc6a3;
    color: white;
}

.width-fit-content {
    width: fit-content;
}