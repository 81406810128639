.datepickercontainer {
  width: 270px !important;
  height: 50px !important;
  border-radius: 9px;
  padding-left: 6px;
  background-color: white;
  border: 1px solid #c7c7c7;
  padding-left: 20px;
}

.datepickercontainer:focus {
  width: 270px !important;
  height: 50px !important;
  border-radius: 9px;
  padding-left: 6px;
  background-color: white;
  border: 1px solid #c7c7c7;
  padding-left: 20px;
}

.datepickercontainer input {
  width: 100%;
  height: 50px !important;
  border-radius: 9px;
  background-color: white;
  padding-left: 20px;
}

.datepickercontainer input::placeholder {
  font-size: 14px;
  color: #c7c7c7;
  padding-left: 20px;
}
