.ratingContainer {
    height: 20px;
    display: flex;
    align-items: center;

    .starIcon {
        font-size: 20px;
        color: #1b3f8f;
    }

    .emptyStarIcon {
        font-size: 20px;
        color: #ccc;
        opacity: 0.85;
    }
}
