.AddNoteHeaderRoot {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  // background: var(--mobileTheme);
  padding: 10px 20px;
  z-index: 100;
  align-items: flex-start;

  .AddNoteHeaderLeftSection {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;

    .AddNoteHeaderArrowIcon {
      color: white;
      cursor: pointer;
    }

    .AddNoteHeaderProfileContainer {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 6px;

      .AddNoteHeaderProfileImage {
        width: 30px;
        height: 30px;
        border-radius: 41px;
      }

      .AddNoteHeaderUserDetails {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .AddNoteHeaderUserName {
          font-size: 18px;
          line-height: 17px;
          font-weight: 700;
          color: white;
        }

        .AddNoteHeaderRole {
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;
          color: white;
        }

        .AddNoteHeaderLessonDetail {
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;
          color: white;
        }
      }
    }
  }

  .AddNoteHeaderRightSection {
    display: flex;
    align-items: flex-end;
    height: 60px;

    .AddNoteHeaderViewSlides {
      text-decoration: underline;
      cursor: pointer;
      font-size: 12px;
      line-height: 17px;
      font-weight: 400;
      color: white;
    }
  }
}
