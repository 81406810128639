.ratingContainer {
    width: 100px;
    height: 20px;
    display: flex;
    align-items: center;
    gap: 3px;
    padding-bottom: 0px;

    .starIcon {
        font-size: 12px;
        color: #1b3f8f;
    }

    .emptyStarIcon {
        font-size: 12px;
        color: #ccc;
        opacity: 0.55;
    }

    .labelText {
        font-size: 10px;
    }
}
