.SlideDetailModalRoot {
    outline: none;
    z-index: 999;

    .SlideDetailModalModalContainer {
        position: absolute;
        bottom: 0;
        top: 30px;
        width: 100%;
        height: 100%;
        background: white;
        outline: none;
        border: none;
        border-radius: 28px 28px 0 0;

        .SlideDetailModalHeaderContainer {
            display: flex;
            justify-content: space-between;

            .SlideDetailModalSlideNumber {
                font-size: 16px;
                margin: 20px;
                font-weight: 700;
                color: #1b3f8f;
            }

            .SlideDetailModalCloseIcon {
                position: absolute;
                font-size: 24px;
                color: black;
                right: 15px;
                cursor: pointer;
                top: 20px;
            }
        }

        .SlideDetailModalContentContainer {
            overflow-y: scroll;
            display: flex;
            height: 100%;
            flex-direction: column;
            padding-bottom: 140px;

            .SlideDetailModalSlidesDetailContainer {
                overflow-y: auto;
                margin-bottom: 80px;
            }

            .SlideDetailModalSlidesBox {
                position: fixed;
                bottom: 0;
                width: 100%;
                z-index: 90;
            }
        }
    }
}
