.GradingVideoQuizMainContainer {
    padding-top: 10%;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #f8f6ff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    margin-bottom: 70px;
    position: relative;

    .contentContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 90px;

        .videoGradeContentContainer {
            display: flex;
            flex-direction: column;
            gap: 20px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-top: 1%;
            margin-top: 50px;
            padding: 20px;
            text-align: center;

            .managerTitle {
                font-size: 16px;
                font-weight: 700;
                color: #1b3f8f;
            }

            .videoGradeVideoContainer {
                display: flex;
                justify-content: center;
                padding-top: 3%;
                gap: 10px;
                align-items: center;

                .paperContainer {
                    display: flex;
                    width: 100%;
                    min-width: 300px;
                    max-width: 500px;
                    height: 60%;
                    padding: 10px 0;
                    justify-content: center;
                    align-items: center;

                    .videoGradeVideoBox {
                        width: 95%;
                        height: 300px;
                    }
                }
            }

            .statusContainer {
                margin: 1rem;
                margin-left: 0px;
                align-items: center;
                display: flex;
                justify-content: flex-start;
                gap: 10px;

                .statusText {
                    font-size: 16px;
                    font-weight: 700;
                }
                .statusButtonsContainer {
                    margin: 1rem;
                    gap: 1rem;
                    display: flex;

                    .statusButtonGroup {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                    }
                }
            }

            .notesContainer {
                width: 100%;
                max-width: 450px;
                background: white;
                height: 50%;
                outline: none;
                border: none;
                border-radius: 5px;
                padding: 20px;
            }
        }
    }

    .submitBtnContainer {
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        background: white;
        height: 80px;
        padding: 0px 10px;
        align-items: center;

        .submitButton {
            cursor: pointer;
            text-transform: capitalize;
            font-size: 16px;
            height: 43px;
            background-color: #135098;
            width: 100%;
            color: white;
            border-radius: 7px;
        }
    }
}
