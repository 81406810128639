.SlideModalRoot {
    outline: none;
    z-index: 999;

    .SlideModalModalContainer {
        position: absolute;
        bottom: 0;
        top: 30px;
        width: 100%;
        height: 100%;
        background: white;
        outline: none;
        border: none;
        border-radius: 28px 28px 0 0;
        padding-bottom: 30px;

        .SlideModalHeaderContainer {
            display: flex;
            justify-content: space-between;

            .SlideModalSlideNumber {
                font-size: 16px;
                margin: 20px !important;
                font-weight: 700;
                color: #1b3f8f;
            }

            .SlideModalCloseIcon {
                position: absolute;
                font-size: 24px;
                color: black;
                right: 15px;
                cursor: pointer;
                top: 20px;
            }
        }

        .SlideModalContentContainer {
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: 100%;
            justify-content: flex-start;
            margin-bottom: 40px;
            padding-bottom: 60px;

            .SlideModalSlideTabsContainer {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                justify-content: center;
                overflow-y: scroll;

                .SlideModalSlideTabWrapper {
                    cursor: pointer;
                }
            }
        }
    }
}
