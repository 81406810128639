.eventsMobileSearchRoot {
    min-width: 200px;
    display: flex;
    max-width: 320px;
    width: 100%;
    height: 45px;
    justify-content: start;
    gap: 4px;
    align-items: center;

    input {
        background: transparent;
        font-size: 14px;
        width: 100%;
        outline: none;
        border: none;
    }
}
