.FeedbackSuccessModalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 282px;
    max-height: 228px;
    background: white;
    border: none;
    border-radius: 7px;

    .FeedbackSuccessCloseIcon {
        position: absolute;
        font-size: 16px;
        color: #1b3f8f;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }

    .FeedbackSuccessContentContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        padding: 20px 10px;

        .FeedbackSuccessTitle {
            color: #1b3f8f;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
        }

        .FeedbackSuccessIconWrapper {
            display: flex;
            justify-content: center;

            .FeedbackSuccessCheckImage {
                width: 50px;
                height: 50px;
            }
        }

        .FeedbackSuccessDescription {
            color: rgba(0, 0, 0, 0.5);
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
    }
}
