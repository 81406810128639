.FeedbackMobileModalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 282px;
    max-height: 324px;
    background: white;
    border: none;
    border-radius: 7px;

    .FeedbackMobileCloseIcon {
        position: absolute;
        font-size: 16px;
        color: #1b3f8f;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }

    .FeedbackMobileContentContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        padding: 20px 10px;

        .FeedbackMobileTitle {
            color: #1b3f8f;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
        }

        .FeedbackMobileSubtitle {
            color: rgba(0, 0, 0, 0.5);
            font-size: 13px;
            font-weight: 400;
            text-align: center;
        }

        .FeedbackMobileRatingContainer {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 20px;
        }

        .FeedbackMobileTextarea {
            background: rgba(27, 63, 143, 0.15);
            border-radius: 4px;
            padding: 12px;
            min-width: 100%;
            max-width: 241px;
            height: 60%;
            min-height: 91px;
            font-size: 12px;
            outline: none;
            border: none;
        }

        .FeedbackMobileSubmitButton {
            background-color: #135098;
            color: white;
            font-size: 12px;
            font-weight: 600;
            border-radius: 5px;
            width: 70px;
            height: 30px;
            padding: 4px 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: capitalize;
            cursor: pointer;
        }
    }
}
