@import "../../../CommonStyles.scss";

.bottom-appbar {
  height: 55px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  .tabs {
    display: flex;
    flex-direction: row;
    height: 100%;
    .tab {
      &--left {
        // width: 100%;
        border-top-right-radius: 20px;
        border-top: 1px solid rgba(167, 161, 161, 0.69);
        // box-shadow: 0px 6px 7px 0px rgba(23, 23, 23, 0.28);
        z-index: 30;
      }
      &--right {
        // width: 100%;
        border-top-left-radius: 20px;
        border-top: 1px solid rgba(167, 161, 161, 0.69);
        // box-shadow: 15px 4px 15px 0px rgba(23, 23, 23, 0.28);
        z-index: 30;
      }
      &--fab {
        width: 53px;
        height: 100%;
        background: transparent;
        border: none;
        display: flex;

        .top {
          width: 55px;
          height: 26px;
          z-index: 20;
          border-bottom-left-radius: 130px;
          border-bottom-right-radius: 130px;
          // background-color: linear-gradient(to bottom, #1B3F8F, #635BFF);
          box-shadow: 10px 25px 0px 25px $hr-selected-tab-color;
          border-bottom: 1px solid rgba(167, 161, 161, 0.69);
          display: flex;
        }
      }
      background: $hr-selected-tab-color;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // border-top: 1px solid #eee;
      box-shadow: 1x 1x 3px #ccc, -1px -1px 3px #ccc;
      font-size: 24px;
      span {
        font-size: 12px;
      }
      i {
        font-size: 15px;
      }
      &.is-active {
        color: #ffffff;
      }
    }
    .fab {
      border-radius: 50%;
      background-color: $hr-selected-color;
      // border: 1px solid #1B3F8F;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      font-weight: bold;
      font-size: 22px;
      color: #fff;
      position: relative;
      justify-content: center;
      transform: translate(7px, -55%);
    }
  }
}
