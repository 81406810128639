.EarningListRoot {
  padding-top: 15px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 70px;

  .toggelContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tableContainer {
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    .headCell {
      font-size: 12px;
      background: #1b3f8f;
      font-weight: 700;
      color: white;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 4px 7px;
      text-align: center;
    }

    .tableBodyContainer {
      overflow: auto;
      min-height: 300px;

      .bodyCell {
        font-size: 10px;
        font-weight: 400;
        color: black;
        width: 25%;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
      }
    }
  }

  .loadContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px 0px;
  }

  .noRecordText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    font-size: 16px;
  }
  .paginationContainer {
    display: flex;
    width: 100%;
    // margin-bottom: 120px;
    justify-content: center;
  }

  .loaderContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0px;
  }
}
