// AddNotesModal.scss

.modal-container {
  display: flex;

  .add-notes-modal {
    width: 600px;
    height: 400px;
    background: white;
    position: absolute;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 40px;

    @media (max-width: 609px) {
      width: 90%;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background: linear-gradient(
        45deg,
        #1b3f8f,
        #635bff
      ); // Assuming $themeColor is defined in your theme
      color: white;
      font-size: 16px;
      font-weight: 700;
    }

    .add-notes-content {
      width: 100%;
      padding: 17px 30px;
    }

    .input-field {
      border: 1px solid #00000040;
      width: 100%;
      border-radius: 12px;
      height: 142px;
      padding: 10px;
    }

    .input-field:focus-visible {
      outline: none;
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 18px;
    }

    .add-notes-button {
      background: linear-gradient(90deg, #1b3f8f 0%, #635bff 100%);
      border-radius: 10px;
      width: 150px;
      height: 50px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
    }

    .cancel-button {
      background: linear-gradient(
        90deg,
        rgba(27, 63, 143, 0.4) 0%,
        rgba(99, 91, 255, 0.4) 100%
      );
      border-radius: 10px;
      width: 150px;
      height: 50px;
      color: #1b3f8f;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
