.page-card-root-container {
  width: 155px;
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 21px 4px #00000014;

  .page-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    object-fit: contain;
  }

  .page-name-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 29px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, #1b3f8f 0%, #635bff 100%);
  }
}
