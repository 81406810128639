.payrollMain {
    padding: 10px 15px !important;
    border: none;
    display: flex;
    margin: 2px 15px 2px 20px!important;
    justify-content: space-between;
    border-radius: 5px;
    box-shadow: none;
    background-color: white;
}

.verticalLine {
    border-left: 1px solid gray;
    height: 50px;
}

.approvalMain {
    padding: 10px 15px !important;
    border: none;
    display: flex;
    margin: 0px 15px 2px 20px!important;
    border-radius: 5px;
    box-shadow: none;
    background-color: white;
}

.approvalIcon {
    margin-left: 50px;
    height: 30px;
}

.approvalButton {
    background: gainsboro !important;
    border-radius: 20px !important;
    padding: 6px 24px !important;
}

.inst-btn {
    text-transform: capitalize!important;
    margin: 60px 0 0 320px;
    /* background: gainsboro !important; */
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 20px !important;
    outline: 0;
}

.inst-btns {
    text-transform: capitalize!important;
    /* margin: 60px 0 0 320px; */

    border-radius: 4px !important;
    color: white!important;
    padding: 4px 20px!important;
    margin-left: 5px;
    background-image: linear-gradient(45deg, #AA076B, #9F0D9C)!important;
}

.inst-btn {
    font-size: 26px;
    color: white;
    padding: 5px;
    margin: 0;
    font-family: "arial";
    border: 0;
}


.wrapper:after {
    content: " ";
    width: 100px;
    height: 2px;
    margin: -4px 6px;
    vertical-align: super;
    background-color: grey;
    display: inline-block;
}
.ActiveLogMain {
    display: flex;
}

.ActiveTitle {
    color: #645E5E;
    font-weight: 700;
}

.ActiveComment {
    color: #A5A1A1;
}

.Account-btn {
    background-color: #217561 !important;
    color: white !important;
    border-radius: 25px !important;
    font-size: 14px !important;
    padding: 6px 17px !important;
}

.ActiveverticalLine {
    border-left: 3px solid gray;
    height: 50px;
    margin-left: 93px;
}

.editIcon {
    margin-top: 20px !important;
    margin-left: 6px !important;
    border: 1px solid !important;
}

.titleColumn {
    background-color: #000000;
    color: white;
    padding: 10px;
}

.paraEdit {
    font-weight: 500 !important;
    padding: 10px;
    font-size: 14px !important;
}
.comment-content img {
    width: 70%; /* Set the maximum width to the container width */
    height: auto; /* Maintain aspect ratio */
    /* Add any other styles as needed */
  }

.titlecloumnName {
    line-height: 3 !important;
}

.ms-auto {
    margin-left: auto;
}

.editSaveButton {
    background-color: #000000 !important;
    color: white !important;
    padding: 10px 37px !important;
}