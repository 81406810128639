.myLibraryMobileRoot {
    width: 100%;
    height: 100%;
    background: #f8f6ff;

    .myLibraryMobileContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 80px;
        overflow: auto;
        padding-bottom: 70px;

        .myLibraryMobileFiltersContainer {
            display: flex;
            /* justify-content: center; */
            align-items: flex-start;
            padding: 0 10px;
            gap: 10px;

            .myLibraryMobileFilterTab {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 7px;
                flex-wrap: wrap;
                /* margin: auto; */
                margin-top: 14px;
                padding-bottom: 4px;
            }
        }
        .myLibraryMobileLoaderContainer {
            display: flex;
            justify-content: center;
            margin: 10px 0;
            background: transparent;
        }
        .myLibraryMobilePaginationStack {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            gap: 14px;
        }
        .myLibraryMobilePaginationContainer {
            display: flex;
            width: 100%;
            justify-content: center;
        }
    }
}
