.eLearningMobileTopNavFeedbackDetailsRoot {
  position: fixed;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  border: none;
  padding: 0px;
  width: 100%;
  top: 0;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 9999;

  .eLearningMobileTopNavFeedbackDetailContainer {
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .eLearningMobileTopNavBackButton {
      font-size: 17px;
      color: #1b3f8f;
    }

    .eLearningMobileTopNavFeedbackDetailTitle {
      position: absolute;
      left: 35%;
      font-size: 14px;
      font-weight: 500;
      color: #212121bf;
    }
  }
}

.eLearningMobileTopNavEarningRoot {
  position: fixed;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  background: linear-gradient(180deg, #1b3f8f 0%, #635bff 100%);
  border: none;
  padding: 0px;
  width: 100%;
  top: 0;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 9999;

  .eLearningMobileTopNavEarningContainer {
    background: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .eLearningMobileTopNavBackButtonContainer {
      position: absolute;
      left: 10px;
    }

    .eLearningMobileTopNavBackButton {
      font-size: 17px;
      color: white;
    }

    .eLearningMobileTopNavEarningTitle {
      // position: absolute;
      // left: 40%;
      font-size: 20px;
      font-weight: 700;
      color: white;
    }
  }
}

.eLearningMobileTopNavMobileAppBar {
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  z-index: 999;
  padding: 0px 20px 10px 20px;
  // background: var(--mobileTheme);

  .eLearningMobileTopNavToolbarContainer {
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    margin-top: 0px;
    width: 100%;

    .eLearningMobileTopNavUserInfo {
      display: flex;
      align-items: center;
      gap: 2px;

      .eLearningMobileTopNavWavingHandIcon {
        width: 16px;
        height: 16px;
      }

      .eLearningMobileTopNavGreetingsTypography {
        font-size: 16px;
        font-weight: 700;
        color: white;
      }
    }

    .eLearningMobileTopNavNotificationIcon {
      width: 24px;
      height: 24px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .eLearningMobileTopNavBellIcon {
        font-size: 13px;
        color: white;
      }
    }

    .eLearningMobileTopNavDashboardIconView {
      width: 28px;
      height: 28px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .eLearningMobileTopNavDashboardIcon {
        font-size: 20px;
        color: white;
      }
    }
  }

  .eLearningMobileTopNavCourseInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .eLearningMobileTopNavCourseInfoTypography {
      font-size: 12px;
      color: white;
    }

    .eLearningMobileTopNavProgressBarContainer {
      width: 70%;
    }

    .eLearningMobileTopNavProgressBarContainer {
      width: 70%;
    }
  }

  .eLearningMobileTopNavSearchToolbar {
    justify-content: center;
    gap: 10px;
    padding-bottom: 10px;
  }

  .eLearningMobileTopNavFeedbacksContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 5px;
    margin-top: -10px;

    .eLearningMobileTopNavFeedbacksTypography {
      font-size: 12px;
      font-weight: 400;
      color: white;
    }
  }
}
