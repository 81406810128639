.slideQuizMainContainer {
    outline: none;
    z-index: 999;
    position: absolute;
    bottom: 0;
    padding: 5px 0;

    .slideQuizContentWrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background: white;
        outline: none;
        border: none;
        padding: 20px;

        .slideQuizContentAlignmentContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: 100%;
            justify-content: flex-start;

            .slideQuizBackArrowWrapper {
                display: flex;
                justify-content: space-between;

                .slideQuizMainBackIcon {
                }
            }

            .slideQuizTopRow {
                margin-top: 30px;
                margin-bottom: 20px;
            }

            .slideQuizQuestionContainer {
                display: grid;
                justify-content: flex-start;
                text-align: start;
                gap: 2px;

                .slideQuizQuestionHeading {
                    color: #6a6a6a;
                    font-size: 16px;
                    font-weight: 700;
                    margin: 0px;

                    .slideQuizQuestion {
                        margin-bottom: 20px;
                        color: #6a6a6a;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }

            .slideQuizOptionsContainer {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: flex-start;
                max-height: 300px;
                overflow-y: auto;
                gap: 20px;
                width: 100%;

                .slideQuizOptionWrapper {
                    width: 270px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 3px;
                    padding: 4px 7px 4px 7px;
                    border: 1px solid #cccccc;
                    border-radius: 21px;
                    cursor: pointer;

                    .slideQuizAlphabetChar {
                        font-size: 16px;
                        color: #135098;
                        font-weight: 400;
                    }

                    .slideQuizOptionText {
                        font-size: 16px;
                        color: #6a6a6a;
                        font-weight: 400;
                        overflow-wrap: break-word;
                        word-break: break-word;
                    }
                }
            }

            .slideQuizBtn {
                width: 225px;
                height: 52px;
                display: flex;
                background-color: #245193;
                border: 1px solid #245193;
                margin: auto;
                border-radius: 4px;
                color: white;
                font-weight: bold;
                font-size: 16px;
                box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.25);

                .slideQuizText {
                    color: white;
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }
    }
}
