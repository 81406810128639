.eventDetailsTopNavRoot {
    width: 100%;
    height: 140px;
    z-index: 9999;
    position: fixed;
    background: white;
    top: 0;
    .eventDetailsTopNavContainer {
        width: 100%;
        height: 140px;
        display: flex;
        flex-direction: column;
        padding: 14px;
        justify-content: space-between;
        .cornerPatternImageContainer {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 88px;
            height: 88px;

            img {
                width: 88px;
                height: 88px;
            }
        }

        .detailContainer {
            display: flex;
            flex-direction: column;
            justify-content: start;
            gap: 2px;
            align-items: start;

            .title {
                font-size: 22px;
                color: black;
                font-weight: 500;
            }
            .date {
                font-size: 14px;
                font-weight: 400;
                color: #000000b2;
            }
        }
    }
}

.hrMobileHeader {
    width: 100%;
    display: flex;
    position: fixed;
    padding: 10px;
    height: 100px;
    margin-bottom: 30px;
    align-items: center;
    background: white;
    justify-content: start;
    z-index: 99;
    top: 0;
    right: 0;
    left: auto;
    flex-shrink: 0;

    .headerContainerHR {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .profileContainerHR {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 7px;

            .profileImageHR {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .profileImg {
                    width: 26px;
                    height: 26px;
                }
            }

            .userInfoContainerHR {
                display: flex;
                align-items: start;
                flex-direction: column;
                justify-content: start;
                gap: 1px;

                .userNameHR {
                    font-size: 16px;
                    font-weight: 700;
                    color: black;
                }

                .welcomeTextHR {
                    font-size: 13px;
                    color: #78787880;
                }
            }
        }

        .searchIconContainerHR {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .searchIcon {
                font-size: 16px;
            }
        }
    }
}

.hrMobileHeaderRoot {
    width: 100%;
    display: flex;
    position: fixed;
    padding: 10px;
    height: 56px;
    margin-bottom: 30px;
    align-items: center;
    background: white;
    box-shadow: 0px 1px 4px 0px #0000001f;
    justify-content: center;
    z-index: 99;

    .backButton {
        position: absolute;
        left: 20px;

        .title {
            font-size: 14px;
            font-weight: 500;
            color: #212121bf;
        }
    }
}

.EventListingHeader {
    background: white;
    z-index: 9999;
    position: fixed;
    top: 0;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 30px;
    font-size: 20px;
    color: black;
    font-weight: 700;
}
