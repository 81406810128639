.earningPaymentMobileRootContainer {
  padding-bottom: 120px;

  .tableContainer {
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    .headCell {
      font-size: 12px;
      background: #1b3f8f;
      font-weight: 700;
      color: white;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 4px 7px;
      text-align: center;
    }

    .tableBodyContainer {
      overflow: auto;
      min-height: 300px;

      .bodyCell {
        font-size: 10px;
        font-weight: 400;
        color: black;
        width: 25%;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;

        .earning-table-body-first-child-pending-status {
          display: flex;
          gap: 3px;
          align-items: center;
        }

        .earning-table-body-row-cell-first-child-text-position {
          font-size: 10px;
          text-align: left;
        }
      }
    }
  }

  .loaderContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0px;
  }
}
